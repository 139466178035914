<template>
    <div class="eatXfbPiceUpdate">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['save','output','lookLog','refresh','goBack']"
                @saveClick="saveData()"  
                @outputClick="exportExcel()" 
                @lookLogClick="goLog()"
                @refreshClick="loadData()" 
                @goBackClick="back()"/>
            <div class="pour">注:价格修改后，客户端需退出重新登录后价格生效!</div>
            
        </div>
        <div class="data-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        node-key="id"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table">
                    <div class="table-box">
                        <el-table class="el-table--scrollable-y" style="width: 100%;height:100%" 
                            ref="tableEl"
                            border
                            highlight-current-row
                            @selection-change="selectFood"
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                            :data="pageTableData"
                        >
                            <el-table-column label="选择" type="selection" width="40" ></el-table-column>
                            <el-table-column prop="EAT_XFCODE" label="菜品编码" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="EAT_XFNAME" label="菜品名称" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="EAT_XFSIZE" label="单位" min-width="80" align="left"></el-table-column>
                            <el-table-column prop="EAT_KINDNAME" label="上级分类" min-width="80" ></el-table-column>
                            <el-table-column prop="DO_XFPRICE" label="当前售价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column prop="DO_XFPRICE" label="执行价" min-width="80" align="right" data-format="number">
                                <template #default="scope">
                                    <div class="num">
                                        <input-pattern class="xfPrice" :data-id="scope.row.EAT_XFBMID" 
                                            v-model="editFoodList[scope.row.EAT_XFBMID].DO_XFPRICE" pattern="number" :min="0" :dplaces="2"
                                            @change="rowSelect(scope.row)"
                                            @keydown.enter="next('.parPrice',scope.$index)"
                                            @focus="$refs.tableEl?.setCurrentRow(scope.row)"
                                            @blur="editFoodList[scope.row.EAT_XFBMID].DO_XFPRICE==''?(editFoodList[scope.row.EAT_XFBMID].DO_XFPRICE=0):''"
                                        ></input-pattern>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="DO_PARPRICE" label="当前会员价" min-width="80" align="right" data-format="number"></el-table-column>
                            <el-table-column prop="DO_PARPRICE" label="执行会员价" min-width="80" align="right" data-format="number">
                                <template #default="scope">
                                    <div class="num">
                                        <input-pattern class="parPrice" :data-id="scope.row.EAT_XFBMID" 
                                            v-model="editFoodList[scope.row.EAT_XFBMID].DO_PARPRICE" pattern="number" :min="0" :dplaces="2"
                                            @change="rowSelect(scope.row)"
                                            @keydown.enter="next('.xfPrice',scope.$index+1)"
                                            @focus="$refs.tableEl?.setCurrentRow(scope.row)"
                                            @blur="editFoodList[scope.row.EAT_XFBMID].DO_PARPRICE==''?(editFoodList[scope.row.EAT_XFBMID].DO_PARPRICE=0):''"
                                        ></input-pattern>
                                    </div>
                                </template>
                            </el-table-column>
                            
                            <el-table-column prop="LASTUPDATED_USER" label="最后发布人" min-width="100" align="left"></el-table-column> 
                            <el-table-column prop="LASTUPDATED_TIME" label="最后发布时间" min-width="130" :formatter="dateFormat" align="left"></el-table-column> 
                        </el-table>
                    </div>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="searchTxt" placeholder="菜品名称、编码或助记码模糊检索"
                            isKeyEnterClose isBlurClose
                            :keyOptions="{isKeyDownEnter:true}"
                            @keydown.enter="onEnter()"
                        ></key-board-input>
                        <div class="float-right">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="billPage()"></span>
                            <span class="page-number">{{ tablePageIndex+1 }}/{{ tablePageSum }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="billPage(1)" ></span>
                            <div class="page-bnt">
                                <el-select v-model="pageSize"  >
                                    <el-option :value="20" label="20条/页"></el-option>
                                    <el-option :value="30" label="30条/页"></el-option>
                                    <el-option :value="50" label="50条/页"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>

<script>
import XLSX from 'xlsx'
export default {
    name:"eatXfbPiceUpdate",
    data() {
        let pageSize= this.$cacheData.get("eatXfbPiceUpdate_pageSize")||20;
        return {
            //选中的节点
            currentNode:null,
            foodMainCategory:[],
            tablePageIndex:0,
            pageSize:pageSize,
            //搜索数据
            searchTxt:"",
            //菜品上下架数据
            foodDoorList:[],
            /**勾选要修改的数据 */
            checkFoods:[],
            /**修改的数据 */
            editFoodList:{},
            batchPrice:0,
            /**高亮 */
            currentIndex:null
        }
    },
    computed:{
        treeData(){
            let tree=[];
            this.foodMainCategory?.forEach(category=>{
                let children=[];
                category.FoodSubCategorys?.forEach(sub=>{
                    children.push({
                        id:sub.SubCategory_ID,
                        label:sub.SubCategory_Code+"-"+sub.SubCategory_Name,
                        SubCategory_ID:sub.SubCategory_ID,
                    })
                });
                if(children.length>0){
                    tree.push({
                        id:category.MainCategory_ID,
                        label:category.MainCategory_Code+"-"+category.MainCategory_Name,
                        children:children
                    });
                }
            })
            return [{label:"全部",children:tree,id:0}];
        },
        tableData(){
            let posFoods=this.foodDoorList;
            if(this.currentNode?.children){
                let foods=[];
                let category= this.foodMainCategory.find(t=>t.MainCategory_ID==this.currentNode.id);
                if(category){
                    foods=posFoods.filter(t=>category.FoodSubCategorys.some(it=>it.SubCategory_ID==t.EAT_KINDID));
                }
                posFoods= foods;
            }else if(this.currentNode){
                posFoods=posFoods.filter(t=>t.EAT_KINDID==this.currentNode.id);
            }
            return posFoods;
        },
        filterData(){
            let data=this.tableData||[];
            if(this.searchTxt.trim()!=''){
                let MenuTxt=this.searchTxt.trim().toLowerCase();
                data=data.filter(t=>(t.EAT_XFCODE||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.EAT_XFNAME||"").toLowerCase().indexOf(MenuTxt)>=0 || (t.PINYIN||"").toLowerCase().indexOf(MenuTxt)>=0);
            }
            
            return data;
        },
        /**分页数据 */
        pageTableData(){
            return this.filterData.slice(this.tablePageIndex*this.pageSize,(this.tablePageIndex+1)*this.pageSize);
        },
        tablePageSum(){
            let length=this.filterData.length;
            if(length>1){
                return Math.ceil(length/this.pageSize);
            }
            return 1;
        }
    },
    watch:{
        pageSize(newVal){
            this.$cacheData.set("eatBindCard_pageSize",newVal);
        },
        tablePageSum(newVal){
            if(newVal<=this.tablePageIndex){
                this.tablePageIndex=0;
            }
        },
        searchTxt(){
            this.tablePageIndex=0;
        },
        pageTableData:{
            handler(){
                let list={};
                this.pageTableData.forEach(it=>{
                    list[it.EAT_XFBMID]={DO_XFPRICE:it.DO_XFPRICE,DO_PARPRICE:it.DO_PARPRICE};
                });
                this.editFoodList=list;
            },
            immediate:true,
        },
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadData();
    },
    methods:{
        back(){
            this.$router.push("setup");
        },
        loadData(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetLimitedTimeFoodPrice", {}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error(d.ResponseHeader.ResultDesc)
                    return;
                }else{
                    let data=d.ResponseBody;
                    this.setMainCategory(data);
                    this.foodDoorList=data;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert("数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
            });
        },
        setMainCategory(data){
            this.currentNode=null;
            let mainCategory=[];
            let mainKey={};
            data.forEach(it=>{
                if(!mainKey[it.MainCategory_ID]){
                    mainKey[it.MainCategory_ID]={
                        MainCategory_ID:it.MainCategory_ID,
                        MainCategory_Code:it.MainCategory_Code,
                        MainCategory_Name:it.MainCategory_Name,
                        FoodSubCategorys:[],
                        subKey:{}
                    };
                    mainCategory.push(mainKey[it.MainCategory_ID]);
                }

                if(!mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]){
                    mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]={
                        SubCategory_ID:it.EAT_KINDID,
                        SubCategory_Code:it.EAT_KINDCODE,
                        SubCategory_Name:it.EAT_KINDNAME
                    }
                    mainKey[it.MainCategory_ID].FoodSubCategorys.push(mainKey[it.MainCategory_ID].subKey[it.EAT_KINDID]);
                }
            })
            this.foodMainCategory=mainCategory;
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        /**数据分页 */
        billPage(type){
            if(type==1){
                if(this.tablePageIndex+1<this.tablePageSum){
                    this.pageSave().then(()=>{
                        this.tablePageIndex++;
                    });
                    
                }else{//下一页
                    this.$message.warning('已到最后一页');
                }
            }else{//上一页
                if(this.tablePageIndex==0){
                    this.$message.warning('已到第一页');
                }
                if(this.tablePageIndex>=1){
                    this.pageSave().then(()=>{
                        this.tablePageIndex--;
                    });
                }
            }
        },
        /**翻页时提示是否先保存 */
        pageSave(){
            return new Promise((resolve,reject) => {
                if(this.checkFoods.length>0){
                    this.$confirm("当前页信息已经被修改,是否保存?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText:"取消",
                        callback: (name) => {
                            if(name=='confirm'){
                                this.saveData(()=>{
                                    resolve();
                                });
                            }else{
                                resolve();
                            }
                        },
                    });
                }else{
                    resolve();
                }
            })
        },
        //勾选的菜品
        selectFood(currentRow){
            this.checkFoods=currentRow;
        },
        /**修改时 勾选行 */
        rowSelect(row){
            this.$refs.tableEl?.toggleRowSelection(row,true);
        },
        //下一个 选中
        next(className,index){
            if(index<this.pageTableData.length){
                this.$el.querySelectorAll(className+"[data-id]")[index]?.focus();
            }
        },
        /**批量 */
        batch(key,newVal){
            this.pageTableData.forEach(it=>{
                this.editFoodList[it.EAT_XFBMID][key]=newVal;
                this.rowSelect(it);
            })
        },
        /**保存修改 
         * @param callback function 请求成功后回调
        */
        saveData(callback){
            let EatXfbmList=[];
            this.checkFoods?.forEach(t=>{
                let editInfo=this.editFoodList[t.EAT_XFBMID];
                if(editInfo){
                    EatXfbmList.push({
                        EAT_XFBMID:t.EAT_XFBMID,
                        DO_XFPRICE:editInfo.DO_XFPRICE,
                        DO_PARPRICE:editInfo.DO_PARPRICE
                    });
                }
            })
            if(EatXfbmList.length==0){
                this.$message.warning('请勾选需要保存的数据');
                return;
            }

            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let data={
                Operator_Name:userInfo?.Login_Name,
                list:EatXfbmList
            }
            this.$httpAES.post("Bestech.CloudPos.SaveLimitedTimeFoodPrice",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    callback && callback();
                    this.$message.success("数据保存成功");
                    d.ResponseBody?.forEach(it=>{
                        this.foodDoorList.some(food=>{
                            if(food.EAT_XFBMID==it.EAT_XFBMID){
                                food.DO_XFPRICE=it.DO_XFPRICE;
                                food.DO_PARPRICE=it.DO_PARPRICE;
                                food.LASTUPDATED_USER=it.LASTUPDATED_USER;
                                food.LASTUPDATED_TIME=it.LASTUPDATED_TIME;
                                return true;
                            }
                            return false;
                        })
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error("数据保存失败："+e);
                console.log("数据保存失败：",e);
            })
        },
        /**Excel导出 */
        exportExcel(){ 
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            var newWorkbook = XLSX.utils.book_new();
            let turnover=[];
            turnover.push(["菜品编码","菜品名称","单位","上级分类","当前售价","当前会员价","最后发布人","最后发布时间"])
            this.filterData.forEach(it=>{
                turnover.push([it.EAT_XFCODE,it.EAT_XFNAME,it.EAT_XFSIZE,it.EAT_KINDNAME,it.DO_XFPRICE,it.DO_PARPRICE,it.LASTUPDATED_USER,this.dateFormat(null,null,it.LASTUPDATED_TIME)])
            })
            let wb = XLSX.utils.aoa_to_sheet(turnover);
            XLSX.utils.book_append_sheet(newWorkbook,wb,"时价菜设置");
            XLSX.writeFile(newWorkbook,"时价菜设置.xlsx")
        },
        /**查看日志 */
        goLog(){
            this.$router.push("eatXfbPiceLog");
        },
        //设置列类名
        setClassName({ row, rowIndex }){
            if(rowIndex==this.currentIndex){//高亮
                return "current-row";
            }
            return '';
        },
    }
}
</script>

<style lang="scss">
@import './eatXfbPiceUpdate.scss'
</style>